import React from "react"
import ContentLoader from "react-content-loader"
import { useMediaPredicate } from 'react-media-hook'
import useWindowSize from '../hooks/useWindowSize'

const LeftNavLoader = ({ numLines, ...otherProps }) => {
  const isSmall= useMediaPredicate('(max-width: 767px)')
  const winSize = useWindowSize()

  return (
    <ContentLoader
      speed={1}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...otherProps}
    >
      {new Array(numLines).fill(" ").map((_, i) => {
        return (
          <rect key={i} x={16} y={i * 20 + 20} rx="5" ry="5" width={isSmall ? winSize.width-36 : 240} height="12" />
        );
      })}
    </ContentLoader>
  )

}

export default LeftNavLoader
