import React from 'react'
import '../styles/modules/video-nav.scss'

const VideoNav = ({ page, eventId, events, handleEventChange }) => {
  return (
    <nav className="video-nav">
      <ul>
        <li className={`nav-list-item top ${eventId === undefined ? 'active': ''}`}>
          {eventId === undefined  
            ? <span>All</span>
            : <span onClick={() => handleEventChange('all')} onKeyDown={() => handleEventChange('all')} role="button" className="nav-link" tabIndex={0}>All</span>
          }
        </li>
        {
          events.map((event, idx) => 
            <li className={`nav-list-item sub ${eventId === event.id ? 'active': ''}`} key={`video-${idx}-${event.id}`}>
              {eventId === event.id  
                ? <span>{event.title}</span>
                : <span onClick={() => handleEventChange(event.id)} onKeyDown={() => handleEventChange(event.id)} role="button" className="nav-link" tabIndex={0}>{event.title}</span>
              }
            </li>
          )
        }
      </ul>
    </nav>
  )
}

export default VideoNav
