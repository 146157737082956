import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { useQueryParam, StringParam, NumberParam, encodeDelimitedArray, decodeDelimitedArray } from 'use-query-params'
import ReactPaginate from 'react-paginate'
import '../styles/modules/pagination.scss'

import VideoNav from '../components/VideoNav'
import VideoTiles from './components/VideoTiles'
import LeftNavLoader from '../components/loaders/LeftNavLoader'
import VideoTilesLoader from '../components/loaders/VideoTilesLoader'
import useWindowSize from '../components/hooks/useWindowSize'
import TagsInput from './components/TagsInput'
import { IoIosSad } from 'react-icons/io'
import { TiDeleteOutline } from 'react-icons/ti'

const eventMap = {
  '06d019ae-cba8-472b-b768-7dcfccf2c342': 'Connect Online 2021',
  '16edf919-e97e-47e9-9b95-383577da79d9': 'Connect Online 2020',
  'all': 'All'
}

/** Uses a comma to delimit entries. e.g. ['a', 'b'] => qp?=a,b */
let CommaArrayParam = {
  encode: function (array) {
    return encodeDelimitedArray(array, ',');
  },
  decode: function (arrayStr) {
    return decodeDelimitedArray(arrayStr, ',');
  }
};

const Videos = () => {
  const l = useQueryParam("l", StringParam)[0]
  const isMounted = useRef(false)
  const { width } = useWindowSize();
  const [hasNoResults, setHasNoResults] = useState(false)
  const [resultsMsg, setResultsMsg] = useState('')

  // state for paging and event filtering
  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam)
  const [selectedEvent, setSelectedEvent] = useQueryParam('event', StringParam)

  // state for video data and total pages
  const [fetchedData, setFetchedData] = useState([])
  const [fetchedEvents, setFetchedEvents] = useState([])
  const [pageCount, setPageCount] = useState(1)

  // state for tags lists
  const [availableTags, setAvailableTags] = useState([])
  const [selectedTags, setSelectedTags] = useQueryParam('tags', CommaArrayParam)

  const fetchVideos = useCallback( async () => {
    const buildResultMessage = () => {
      let resultMessage  = ''

      const hasTags = selectedTags !== undefined && selectedTags.length > 0
      const hasEvent = selectedEvent !== 'all' && selectedEvent !== undefined

      let eventTitle = eventMap[selectedEvent]

      if (hasTags && hasEvent) {
        resultMessage = `Showing videos from ${eventTitle} with tag(s) ${selectedTags.join(', ')}`
      } else if (hasTags) {
        resultMessage = `Showing videos with tag(s) ${selectedTags.join(', ')}`
      } else if (hasEvent) {
        resultMessage = `Showing videos from ${eventTitle}`
      }

      setResultsMsg(resultMessage)
    }


    setFetchedData([]) // show the loaders while we prepare the new video set
    let fetchUrl = `${l === undefined ? process.env.GATSBY_API_URL : ''}/v1/tags/videos?pageSize=12`

    if (currentPage) {
      fetchUrl += `&page=${currentPage}`
    } else {
      fetchUrl += `&page=0`
    }

    if (selectedEvent !== 'all' && selectedEvent !== undefined) {
      fetchUrl += `&event=${selectedEvent}`
    }

    if (selectedTags && selectedTags.length > 0) {
      let tagParams = ''
      selectedTags.forEach((tag) => {
        tagParams += `&tags[]=${tag}`
      })

      fetchUrl += encodeURI(tagParams)
    }

    const result = await fetch(fetchUrl, { method: 'get', mode: 'cors' })
    const data = await result.json()
    const pageCount = result.headers.get("x-page-count")

    if (data.length < 1) {
      setHasNoResults(true)
    } else {
      setHasNoResults(false)
    }

    buildResultMessage()
    setFetchedData(data)
    setPageCount(parseInt(pageCount))
  }, [currentPage, l, selectedEvent, selectedTags])

  const fetchAllTags = () => {
    let fetchUrl = `${l === undefined ? process.env.GATSBY_API_URL : ''}/v1/tags`
    fetch(fetchUrl, { method: 'get', mode: 'cors' })
      .then(response => response.json())
      .then(data => {
        if (data) {
          setAvailableTags(data)
        }
      })
  }

  // Function that handles when pagination touched
  const handlePaging = (selectedPage) => setCurrentPage(selectedPage.selected, 'pushIn')

  // functions for tags
  const handleTagSelect = (e) => {
    const newArray = selectedTags ? [...selectedTags] : []
    newArray.push(e.target.innerText)
    setCurrentPage(0)
    setSelectedTags(newArray, 'pushIn')
  }

  const handleTagDeselect = (e) => {
    const newArray = [...selectedTags]
    let idx = newArray.indexOf(e.target.innerText)
    if (e.target.innerText === undefined) {
      idx = newArray.indexOf(e.target.parentElement.innerText)
    }
    if (idx !== -1) {
      newArray.splice(idx, 1)
    }
    setSelectedTags(newArray, 'pushIn')
  }

  const handleEventChange = (eventId) => {
    setCurrentPage(0)
    setSelectedEvent(eventId, 'pushIn')
  }

  const handleFilterClear = () => {
    setSelectedTags([], 'pushIn')
    setSelectedEvent('all')
  }

  // TODO: fix event filtering, currently the /tags/ URL doesn't know that param

  // Initial Effect that runs on page load
  useEffect(() => {
    // Get All Events (We pass this into the VideoNav)
    const fetchEvents = async () => {
      let fetchUrl = `${l === undefined ? process.env.GATSBY_API_URL : ''}/v1/events`
      const result = await fetch(fetchUrl, {method: 'get', mode: 'cors'}).catch((e) => {
        console.log(e)
      })
      const data = await result.json()
      setFetchedEvents(data)
    }

    fetchVideos()
    fetchEvents()
    fetchAllTags()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (isMounted.current) {
      fetchVideos()
    } else {
      isMounted.current = true
    }
  }, [currentPage, selectedEvent, selectedTags, fetchVideos])

  return (
    <div className={`page--videos`}>
      <Helmet title={`Videos | Couchbase Developer Portal`} />
      <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row">
        {fetchedEvents.length > 1
          ?
          <div className="video-nav-container">
            <VideoNav page={currentPage} eventId={selectedEvent} events={fetchedEvents} handleEventChange={handleEventChange} />
          </div>
          :
          <LeftNavLoader numLines={3} /> // Pass in props to determine lines per breakpoint?
        }
        <div className="video-details-container">
          <div className="flex flex-col">
            <div className={`w-full`}>
              <h1>Videos</h1>
              <p className='text-xs'>Select an event on the left and/or click the tags below to filter</p>

              <TagsInput
                availableTags={availableTags}
                selectedTags={selectedTags}
                handleTagSelect={handleTagSelect}
                handleTagDeselect={handleTagDeselect}
              />

              <div className='mt-4 -mb-3'>
                <span className='text-lg'>{resultsMsg}</span>
                {
                  resultsMsg &&
                  <button
                    className='relative cursor-pointer text-sm font-semibold inline py-1 px-2 rounded text-red-400 bg-red-100 ml-2 mr-2 mb-1 hover:opacity-75 hover:drop-shadow-md hover-fade'
                    onClick={handleFilterClear}
                  >
                      <TiDeleteOutline className='inline text-lg mr-1.5 mb-0.5'/>
                      Clear
                  </button>
                }
              </div>

              {hasNoResults ?
                <div className='mx-auto w-1/2 text-center py-14'>
                  <h4>
                    <IoIosSad className='mx-auto text-7xl block mb-6'/>
                    No Videos Found
                  </h4>
                </div>
                :
                <>
                {fetchedData.length > 0
                    ? <VideoTiles videos={fetchedData} numColumns={4} showDescription={true}/>
                    : <VideoTilesLoader
                      colsXS={1} colsSM={2} colsMD={2} colsLG={2} colsXL={3} cols2XL={4}
                    />
                }
                </>
              }


              {fetchedData.length > 0
                ? <ReactPaginate
                  previousLabel="< prev"
                  nextLabel="next >"
                  breakLabel="..."
                  pageCount={pageCount}
                  marginPagesDisplayed={width < 550 ? 0 : 2}
                  pageRangeDisplayed={width < 550 ? 3 : 5}
                  onPageChange={handlePaging}
                  forcePage={currentPage}

                  containerClassName='pagination'
                  previousClassName='previous'
                  previousLinkClassName='previousLink'
                  breakClassName='break'
                  breakLinkClassName='breakLink'
                  pageClassName='page'
                  pageLinkClassName='pageLink'
                  nextClassName='next'
                  nextLinkClassName='nextLink'

                  hrefBuilder={() => `#`}
                />
                : null
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Videos
