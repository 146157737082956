import React from 'react'
import { TiDeleteOutline } from 'react-icons/ti'


function TagsInput({ availableTags, selectedTags, handleTagSelect, handleTagDeselect }) {
  return (
    <div>
      {selectedTags && selectedTags.map((tag) => {
        return (
          <span key={`sel-${tag}`} onClick={handleTagDeselect} onKeyUp={handleTagDeselect} role='button' tabIndex={0} className='relative cursor-pointer text-xs font-semibold inline-block py-1 px-2 rounded text-blue-400 bg-blue-100 capitalize last:mr-0 mr-2 mb-1 hover:opacity-75 hover:drop-shadow-md hover-fade'>
            {tag}
            <TiDeleteOutline className='absolute -top-2 -right-2 bg-white rounded-xl text-lg' />
          </span>
        )
      })}
      {availableTags.map((tag) => {
        if (selectedTags && selectedTags.includes(tag)) {
          return null
        }
        return (
          <span key={`avail-${tag}`} onClick={handleTagSelect} onKeyUp={handleTagSelect} role='button' tabIndex={0} className='cursor-pointer text-xs font-semibold inline-block py-1 px-2 rounded text-slate-500 bg-slate-200 capitalize last:mr-0 mr-2 mb-1 hover:opacity-90 hover:drop-shadow-md hover-fade'>
            {tag}
          </span>
        )
      })}
    </div>
  )
}

export default TagsInput
